


.top {position: relative;
     padding-bottom: 56.25%;
     height: 0;
     overflow: hidden;
  

}






.video-embed-container{

    height: 600px !important;
}



.top iframe{

    width: 100% !important;
    height: 900px !important; 
    margin-top: 15px
}

@media (min-width: 768px) and (max-width: 1024px) {
  
    /* CSS */
    .top iframe {
    width: 100% !important;
    height: 700px !important;
    margin-top: -15px;
}
    



video{

    width: 100% !important;
    height: 700px;
    margin: 0 auto;
}

.video-full-frame{

    height: 500px !important;
    width: 100% !important;
}
  }
  
  /* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    /* CSS */

    .top iframe {
        width: 100% !important;
        height: 700px !important;
        margin-top: -15px;
    }

    .video-iframe  {
        border: none; /* Remove borders */
        height: 230px  !important; 
        width: 100% !important;
        
        /* width: 100%; */
        display: block; /* Ensure it renders as a block */
  
        top: 11; 
        left: 0;
        bottom:12;
    }

    video{

        width: 100% !important;
        height: 700px;
        margin: 0 auto;
    }
    .video-full-frame{

        height: 500px !important;
        width: 100% !important;
    }
    
  }

  @media only screen and (max-width: 485px){

    .video-iframe  {
        border: none; /* Remove borders */
        height: 230px  !important; 
        width: 100% !important;
        
        /* width: 100%; */
        display: block; /* Ensure it renders as a block */
     
        top: 11; 
        left: 0;
        bottom:12;
    }

  }

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .top iframe{

        width: 100% !important;
        height: 330px !important; 
        margin-top: -15px
    }


    .video-iframe  {
        border: none;
        height: 400px !important;
        width: 100% !important;
        /* width: 100%; */
        display: block;
        /* position: fixed; */
        position: relative;
        top: 11;
        left: 0;
        bottom: 12;
    }

    .py-5{

        display: none;
    }

    video{

        width: 100% !important;
        height: 400px;
        margin: 0 auto;
    }
  }

div#player.video-embed-container.flowplayer {
  
    /* height: 100% !important; */
    /* width: 100% !important; */
     width: 1200px !important;
    height: 675px !important 
    }



    .video-full-frame{

        width: 1200px !important;
        height: 700px !important
    }


    .fp-engine.fp-ui{

        height: 1200px !important;
        width: 1200px !important;
    }

    #player{

        width: 1200px;
        height: 600px;
    }