

.top {position: relative;
     padding-bottom: 56.25%;
     height: 0;
     overflow: hidden;
      max-width:100%;

}

.bottom {

    position: absolute;

     top: 0;
     
     left: 0;
     
     width: 100%;
     
     height: 575px;

}


.carousel-item .img-fluid {
     width:100% !important;
   }


.row-fluid{

     width: 100%
}

.carousel-item a {
     display: block;
     width:100%;
   }
