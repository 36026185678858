/* src/DownloadPage.css */
.download-page {
    text-align: center;
    margin-top: 50px;
  }
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .download-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .store-badge {
    width: 180px;
    height: auto;
  }
  
  .pwa-info {
    margin-top: 30px;
  }
  
  button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #0078d4;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #005a9e;
  }
  