body {
  margin: 0;
  padding:0;

  height: 100%;
  margin: 0;
 
  font-family: 'Lato',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;

  background-color: #121212 !important;
  color:#fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./fonts/Lato/Lato-Light.ttf) format('truetype');
}

.h2{

  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
  
}
