.show-cat{

    display: flex;
  
    flex-direction: row;
    justify-content: end;
}
.cat{

    margin:4px
}
.show-links {
    float:none !important;
}


.show-images{
    width: 100%;
    height: 356px;
    min-height: 356px;
}