div.appointment{

    position: relative;
      background-image: url("/src/assets/img/live.jpg");
    
    
      height: 500px !important;
    
      width: 1200px;
     
      background-repeat: no-repeat, repeat;
    
      background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover
    }
  
  
  
  
  .our-container{
  
position: relative;
  
  }
  
  .appointment-desc{
  
    position: absolute;
    top: 120px;
    bottom: 10px;
    left: 0;
    right: 0;
  
    margin:0 auto;
  
    text-align: center;
  }